<template>
  <FormCard
    :readonly="isCurrentTab(TABS.COMPETENCIES) && isActive"
    :is-loading="isLoading"
    :hide-actions="isCurrentTab(TABS.INSTRUCTORS)"
    @click:cancel="onCancel"
    @click:save="onSaveForm"
  >
    <template #tabs>
      <VTabs :current-tab="currentTab" :tabs="tabs" @click="onClickUpdateTab" />
    </template>

    <template #content>
      <Details
        v-if="isCurrentTab(TABS.DETAILS)"
        ref="details"
        :readonly="isActive"
        :data="data"
        @click:save="$emit('click:save', $event)"
      />

      <Instructors
        v-if="isCurrentTab(TABS.INSTRUCTORS)"
        :id="id"
        :endpoint="endpoint"
        :instructors="data.instructors"
        @add:instructor="$emit('refresh')"
        @remove:instructor="$emit('refresh')"
      />

      <Competencies
        v-if="isCurrentTab(TABS.COMPETENCIES)"
        :id="id"
        ref="competencies"
        :readonly="isActive"
        :endpoint="endpoint"
        :competencies="data.competencies"
        @add:competency="$emit('refresh')"
        @remove:competency="$emit('refresh')"
      />
    </template>
  </FormCard>
</template>

<script>
import { computed, ref } from "vue";
// Composables
import useForm from "@/composables/useForm";
import useTabs from "@/composables/useTabs";
import useLesson from "@/composables/useLesson";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Components
import VTabs from "@/components/VTabs";
import FormCard from "@/components/FormCard";
import Details from "./forms/Details";
import Instructors from "@/components/templates/Instructors";
import Competencies from "@/components/templates/Competencies";

export default {
  components: {
    VTabs,
    Details,
    Instructors,
    Competencies,
    FormCard
  },
  mixins: [FormMixin],
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  emits: ["update:tab", "click:save", "refresh"],
  setup(props, context) {
    // Constants
    const TABS = {
      DETAILS: "details",
      INSTRUCTORS: "instructors",
      COMPETENCIES: "competencies"
    };

    // Composables
    // eslint-disable-next-line
    const { buildTab, currentTab, updateTab, isCurrentTab } = useTabs(TABS.DETAILS);
    const { isActive, onCancel, isCreate } = useForm(props, context);
    const { endpoint } = useLesson();

    // Data
    const details = ref(null);
    const competencies = ref(null);

    // Computed
    const tabs = computed(() => {
      return Object.values(TABS).map(tab => {
        return buildTab({
          name: tab,
          hideIcon: true,
          disabled: tab !== TABS.DETAILS && isCreate.value
        });
      });
    });

    // Methods
    const onSaveForm = async () => {
      switch (currentTab.value) {
        case TABS.DETAILS:
          await details.value.onSave();
          break;
        case TABS.COMPETENCIES:
          await competencies.value.onSave();
          break;
        default:
      }
    };

    const onClickUpdateTab = tab => {
      updateTab(tab);
      context.emit("update:tab", currentTab.value);
    };

    return {
      TABS,
      tabs,
      details,
      competencies,
      onSaveForm,
      onClickUpdateTab,
      // useLesson
      endpoint,
      // useForm
      isActive,
      onCancel,
      // useTabs
      currentTab,
      isCurrentTab
    };
  }
};
</script>
