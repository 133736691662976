<template>
  <div class="grid grid-cols-2 gap-x-8 gap-y-6">
    <LocalizedInput
      component="VText"
      :model-value="value.name"
      :errors="v.name"
      :label="$t('app.name')"
      :lang="lang"
      :readonly="readonly || locked"
      field="name"
      class="col-span-2"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    />

    <LocalizedInput
      component="VWysiwyg"
      :model-value="value.details"
      :errors="v.details"
      :label="$t('app.details')"
      :lang="lang"
      class="col-span-2"
      field="details"
      :readonly="readonly || locked"
      @update:modelValue="onUpdate"
    />

    <VSelect
      :model-value="v.type.$model"
      :label="$t('app.type')"
      :errors="v.type.$errors"
      class="col-span-2"
      :options="options.type"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
      @update:modelValue="onTypeChange"
    >
      <template #hint>
        {{ $t("hints.teaching_lessons_type") }}
      </template>
    </VSelect>

    <LocalizedInput
      v-if="isTypeContentLocation"
      component="VText"
      :model-value="value.content_location"
      :errors="v.content_location"
      :label="$t('app.content_location')"
      :lang="lang"
      class="col-span-2"
      field="content_location"
      :readonly="readonly || locked"
      @update:modelValue="onUpdate"
      @keyup.enter="onSave"
    />

    <LocalizedInput
      v-if="isTypeFile"
      component="VFile"
      :model-value="value.file_id"
      :options="value.file_id"
      :errors="v.file_id"
      :label="$t('app.file_id')"
      :lang="lang"
      :accept="accept"
      class="col-span-2"
      field="file_id"
      :readonly="readonly || locked"
      @update:modelValue="onUpdate"
    />

    <LocalizedInput
      v-if="isTypeVideoUpload"
      component="VUploadFile"
      :model-value="value.content_location"
      :options="value.content_location"
      :label="$t('app.content_location')"
      :lang="lang"
      :accept="accept"
      class="col-span-2"
      field="content_location"
      :readonly="readonly || locked"
      @update:modelValue="onUpdate"
    />

    <VSelect
      v-model="v.department_id.$model"
      :label="$t('app.department_id')"
      :errors="v.department_id.$errors"
      class="col-span-2"
      :options="options.department_id"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VText
      v-model="v.duration.$model"
      :label="$t('app.duration')"
      type="number"
      :errors="v.duration.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      v-model="v.duration_type.$model"
      :label="$t('app.duration_type')"
      :errors="v.duration_type.$errors"
      :options="options.duration_type"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <VSelect
      :model-value="v.status.$model"
      :label="$t('app.status')"
      :errors="v.status.$errors"
      :options="statusOptions"
      :readonly="locked"
      @update:modelValue="onStatusChange"
      @keyup.enter="onSave"
    />

    <VSwitch
      v-model="v.sys_admin_locked.$model"
      :disabled="!isSysAdmin"
      :label="$t('app.sys_admin_locked')"
      true-value="Yes"
      false-value="No"
      :readonly="readonly || (!isSysAdmin && locked)"
      :errors="v.sys_admin_locked.$errors"
      @keyup.enter="onSave"
    />

    <VDatePicker
      v-model="v.available_from.$model"
      :label="$t('app.available_from')"
      :readonly="locked"
      @keyup.enter="onSave"
    />

    <VDatePicker
      v-model="v.available_until.$model"
      :label="$t('app.available_until')"
      :readonly="locked"
      @keyup.enter="onSave"
    />

    <VDatePicker
      v-if="!isCreate"
      v-model="v.date_activated.$model"
      readonly
      :errors="v.date_activated.$errors"
      :label="$t('app.date_activated')"
    />

    <VDatePicker
      v-if="!isCreate"
      v-model="v.date_archived.$model"
      readonly
      :errors="v.date_archived.$errors"
      :label="$t('app.date_archived')"
    />

    <VTags
      v-model="v.tags.$model"
      :label="$t('app.tags')"
      :readonly="locked"
      :errors="v.tags.$errors"
    />

    <VText
      v-model="v.version.$model"
      :label="$t('app.version')"
      :errors="v.version.$errors"
      :readonly="readonly || locked"
      @keyup.enter="onSave"
    />

    <WarningModal
      v-if="isModalVisible"
      id="warning"
      :warning="warning"
      @click="hideModal"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useOptions from "@/composables/useOptions";
import useLesson from "@/composables/useLesson";
import useModal from "@/composables/useModal";
import useForm from "@/composables/useForm";
// Mixins
import FormMixin from "@/mixins/FormMixin";
// Components
import WarningModal from "@/components/modals/WarningModal";

export default {
  components: {
    WarningModal
  },
  mixins: [FormMixin],
  emits: ["click:save"],
  setup(props, context) {
    // Misc
    const { t } = useI18n();

    // Inject
    const lang = inject("lang");

    // Data
    const warning = ref("");

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { isModalVisible, showModal, hideModal } = useModal("#warning");

    const {
      value,
      rules,
      options,
      localizedFields,
      getOptions,
      isFile,
      isContentLocation,
      isTypeContentLocation,
      isTypeFile,
      isTypeVideoUpload
    } = useLesson();

    const {
      v,
      onUpdate,
      isActive,
      locked,
      isSysAdmin,
      onSave,
      isCreate,
      clearLocalizedValue
    } = useForm(props, context, {
      value,
      rules,
      localizedFields,
      lang
    });

    // Computed
    const statusOptions = computed(() => {
      return options.value.status.map(option => {
        const isOptionUnderReview =
          ALL_OPTIONS.UNDER_REVIEW.value === option.value;

        return {
          ...option,
          disabled: isOptionUnderReview && isActive.value
        };
      });
    });

    const accept = computed(() => {
      const types = {
        [ALL_OPTIONS.AUDIO.value]: "audio/*",
        [ALL_OPTIONS.VIDEO.value]: "video/*",
        [ALL_OPTIONS.DOCUMENT.value]: "*",
        [ALL_OPTIONS.SLIDES.value]: "*"
      };

      return types[value.value.type] ?? "*";
    });

    // Methods
    const onTypeChange = newType => {
      const oldType = v.value.type.$model;

      if (isFile(oldType) && isContentLocation(newType)) {
        clearLocalizedValue(value.value.file_id);
      }

      if (isContentLocation(oldType) && isFile(newType)) {
        clearLocalizedValue(value.value.content_location);
      }

      v.value.type.$model = newType;
    };

    const onStatusChange = newStatus => {
      const currentStatus = props.data?.status;

      const active = ALL_OPTIONS.ACTIVE.value;
      const archived = ALL_OPTIONS.ARCHIVED.value;
      const underReview = ALL_OPTIONS.UNDER_REVIEW.value;

      if (currentStatus === underReview && newStatus === active) {
        warning.value = t("app.activating_lesson");
        showModal();
      }

      if (currentStatus === active && newStatus === archived) {
        warning.value = t("app.archiving_lesson");
        showModal();
      }

      v.value.status.$model = newStatus;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getOptions();
    });

    return {
      accept,
      isTypeContentLocation,
      isTypeFile,
      isTypeVideoUpload,
      onTypeChange,
      onStatusChange,
      warning,
      value,
      statusOptions,
      // useForm
      isCreate,
      clearLocalizedValue,
      v,
      options,
      onUpdate,
      locked,
      isSysAdmin,
      onSave,
      lang,
      // useModal
      isModalVisible,
      hideModal
    };
  }
};
</script>
